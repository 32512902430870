import React from "react";
import { useAuth } from "react-oidc-context";

import jwt_decode from "jwt-decode";

interface Props {
  token: string;
}

export default function UserPatient({ token }: Props) {
  const decodedIdToken: any = jwt_decode(token) as object;

  return (
    <div>
      <h3>
        Call with Patient: {decodedIdToken["urn:telematik:claims:display_name"]}
      </h3>
      <h4>Patient KVNR: {decodedIdToken["urn:telematik:claims:id"]}</h4>
    </div>
  );
}
