import React from "react";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";
import Patient from "./components/Patient";
import Doctor from "./components/Doctor";
import { Space } from "antd";

export default function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  let roomId = searchParams.get("roomId");
  let tan = searchParams.get("tan");

  if (roomId) localStorage.setItem("roomId", roomId);
  if (tan) localStorage.setItem("tan", tan);

  if (!roomId) roomId = localStorage.getItem("roomId");
  if (!tan) tan = localStorage.getItem("tan");

  const canContinue = roomId && tan;

  return (
    <div className="text-center ">
      <div style={{ marginBottom: "60px" }}>
        <Space>
          <img
            style={{ width: "160" }}
            width="160px"
            src="/logo-full-mimoto.svg"
          />
          <div style={{ width: "800px" }}></div>
          <img
            style={{ width: "160" }}
            width="180px"
            src="https://arztkonsultation.de/wp-content/uploads/2023/04/AK-Logo-ohne-Claim-RGB-2500px-e1680700232874.png"
          />
        </Space>
      </div>

      <h1>azuma mimoto & artzkonsultation - Integration example</h1>

      <p style={{ marginTop: "30px" }}>
        This example demonstrates how the german <b>Gesundheits-ID</b> can be
        integrated via the SaaS solution <b>azuma mimoto</b> with the{" "}
        <b>video consultation solution</b> of <b>artzkonsultation</b>.
      </p>

      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="patient" element={<Patient />} />
        <Route path="doctor" element={<Doctor />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>

      <div>
        <div>
          <Space>
            <p style={{ marginTop: "30px" }}>Room ID: {roomId ?? "-"}</p>
            <div style={{ width: "800px" }}></div>
            <p style={{ marginTop: "30px" }}>TAN: {tan ?? "-"}</p>
          </Space>
        </div>
      </div>
    </div>
  );

  function Index() {
    return (
      <div style={{ marginTop: "80px" }}>
        <div>Please select the demo:</div>

        <div style={{ marginTop: "30px" }}>
          <a
            href="patient"
            className={`btn btn-secondary ${!canContinue && "disabled"}`}
            style={{ margin: "50px" }}
          >
            Patient
          </a>
          <a
            href="doctor"
            className={`btn btn-secondary ${!canContinue && "disabled"}`}
            style={{ margin: "50px" }}
          >
            Doctor
          </a>
        </div>
      </div>
    );
  }

  function NoMatch() {
    return (
      <div>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>
    );
  }
}
