import { Card } from "antd";
import { useAuth } from "react-oidc-context";
import React, { useState } from "react";
import User from "./User";
import UserPatient from "./UserPatient";

const baseHostUrl = process.env.REACT_APP_HOST_URL;
const baseApiUrl = process.env.REACT_APP_API_URL;

export default function Doctor() {
  const [loadedSession, setLoadedSession] = useState<string>();

  const roomId = localStorage.getItem("roomId");
  const tan = localStorage.getItem("tan");

  React.useEffect(() => {
    const getSession = async () => {
      const result = await fetch(
        `${baseApiUrl}/api/ak/session?tanId=${tan}&roomId=${roomId}`,
        {
          method: "GET",
        },
      );
      if (result.status !== 200) throw new Error("Error updating session");
      setLoadedSession(await result.text());
    };

    if (loadedSession) return;

    getSession();
  }, [loadedSession]);

  if (!loadedSession)
    return (
      <div className="d-flex justify-content-center">
        <div
          className="spinner-border text-primary mb-3 mySpinner"
          role="status"
          id="loading"
        ></div>
      </div>
    );

  return (
    <>
      <div>
        {loadedSession && (
          <div>
            <div style={{ marginTop: "40px" }}>
              <UserPatient token={loadedSession} />
            </div>

            {roomId && tan ? (
              <iframe
                style={{ marginTop: "40px" }}
                src={`https://azuma-demo.arztkonsultation.de/video/${roomId}?tan=${tan}`}
                height="600"
                width="1000"
                title="arztkonsultation"
                allow="camera *; microphone *"
              ></iframe>
            ) : (
              <div>Something went wrong. no RoomId / Tan found</div>
            )}
          </div>
        )}
      </div>
      <div style={{ marginTop: "50px" }}>
        <hr />
        <a href={`/?roomId=${roomId}&tan=${tan}`} className="btn">
          Home
        </a>
      </div>
    </>
  );
}
