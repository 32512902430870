import React from "react";
import { useAuth } from "react-oidc-context";

import jwt_decode from "jwt-decode";

export default function User() {
  const auth = useAuth();
  if (!auth.isAuthenticated) return <></>;

  const decodedIdToken: any = jwt_decode(auth.user!.id_token!) as object;

  return (
    <div>
      <h3>Hello {decodedIdToken["urn:telematik:claims:display_name"]}</h3>
      <h4>KVNR: {decodedIdToken["urn:telematik:claims:id"]}</h4>
    </div>
  );
}
