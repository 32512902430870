import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "react-oidc-context";

const authority: string = "https://oidcf.develop.azuma-health.tech";
const scope: string =
  "urn:telematik:alter urn:telematik:display_name urn:telematik:email urn:telematik:geschlecht urn:telematik:geburtsdatum urn:telematik:given_name urn:telematik:versicherter openid";
const baseHostUrl = process.env.REACT_APP_HOST_URL;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
const onSigninCallback = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider
        scope={scope}
        authority={authority}
        client_id={"d5e980f8-0b4f-434f-943c-2860edfa464b"}
        redirect_uri={`${baseHostUrl}`}
        onSigninCallback={onSigninCallback}
      >
        <div
          className="d-flex flex-column flex-root"
          style={{ height: "100%" }}
        >
          <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={{
              backgroundImage: "url('/assets/media/azuma_14_neutral.png')",
            }}
          >
            <div className="d-flex flex-center flex-column flex-column-fluid p-md-10 p-sd-5 pb-lg-20">
              <div className="boxBackgroundClass w-lg-1200px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                <div
                  className="d-flex flex-stack"
                  style={{ display: "inline-block" }}
                >
                  <App />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
